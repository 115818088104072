.visit-report-filter {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    background: #fff;
    padding: 20px 20px 10px;
    border-radius: 8px;
fieldset {
    width: calc(16.6% - 10px);
    margin:0px 5px 10px;
}
.btn-submit-reset{
    margin-bottom: 10px;
    margin-left: 10px;
}
}
.visit-report-table-outer{
    .visit-report-table-heading{
        display: flex;
        align-items: flex-end;;
        justify-content: space-between;
        padding: 20px 0px;
    }
    .visit-report-table{
        td{
            vertical-align: top;
        }
        th,td{
            &:first-child{
                width: 16%;
                padding-left: 10px;
            }
            &:nth-child(2){
                width: 11%;
            }
            &:nth-child(3){
                width: 12%;
            }
            &:nth-child(4){
                width: 9%;
            }
            &:nth-child(5){
                width: 9%;
            }
            &:nth-child(6){
                width: 10%;
            }
            &:nth-child(7){
                width: 13%;
                position: relative;
            }
            &:nth-child(8){
                width: 9%;
            }
            &:nth-child(9){
                width: 9%;
            }
            &:last-child{
                padding-right:10px ;
                width: 10%;
            }
        }
        th,.dealer_visit_report_type {
            width: 7% !important;
        }
        th,.dealer_visit_visited_by{
            width: 13% !important;
        }
        .remark-txt {
            height: 39px;
            overflow: hidden;
            display: block;
        }   
        .more-opt {
            position: absolute;
            right: 20px;
            bottom: 10px;
            background: #fff;
            padding-left: 15px;
            text-decoration: underline;
            color: $heading-color;
        
        &::before {
            content: "... ";
            position: absolute;
            left: 0;
            text-decoration: none;
            color: $heading-color;
        }
    }
    .pending-txt{
        color: #FF4747;
    }
    }
}
.d-block{
    display: block;
}
.bg-white{
    background: $white;
}
 .radius{
     border-radius: 8px;
 }

 .no-visit-report-outer{
    .dealer-user-mapping-tabs{
        padding-bottom: 10px;
    }
    .table-heading-filter-no-visit{
        border-radius: 8px;
        background: $white;
        margin: 20px 0;
    }
    .dealer-not-visit-table{
        table{
            th{
                &:first-child{
                    width: 15%;
                }
                &:nth-child(2){
                    width: 17%;
                }
                &:nth-child(5){
                    width: 15%;
                }
            }
            td{
                // &:nth-child(7){
                //     padding: 13px 9px !important;
                // }
            }
            .subtable{
                td{
                height: 82px;
                span{
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                }
            }
        }
    }
 }
 .filter-user-visit{
     .filter-right-panel{
         flex-wrap: wrap !important;
     }
    fieldset{
        margin-bottom: 10px;
        width: 280px !important;
        &.single-select{
            width: 280px !important;
        }
    } 
    .btn-submit-reset{
        margin-bottom: 10px;
    }   
 }
 .user-visit-table{
    table{
        th,td{
            &:nth-child(1){
                width: 15%;
            }
            &:nth-child(2){
                width: 12%;
            }
            &:nth-child(3){
                width: 12%;
            }
            &:nth-child(4){
                width: 22%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &:nth-child(5){
                width: 18%;
            }
            &:nth-child(6){
                width: 12%;
            }
            &:nth-child(7){
                width: 12%;
            }
        }
    }
 }

 .executive-selfie-popup{
    .modal-body{
        overflow-y: hidden;
    }
 }

 .executive-listing{ 
    &.data-table{
         &.all-executive-listing-table{
            table{
                th,td{
                    &:last-child{
                        width: 7%;
                    }
                }
            }
         }
    }
}

a.view_selfie{
    cursor: pointer;
    text-decoration: underline;
    color: $text-color;
    &:hover{
        color: $accent-color;
    }
}

.city_color span{
    color: rgba(0, 0, 0, 0.6) !important;
}

.wrap-cell{
    word-break: break-all;
}

.custom-anchor-tag{
    color: #0bbddd;
    text-decoration: underline;
}

.no-visit-export{
    text-align: end;
    margin-right: 25px;
    margin-bottom: 20px;
}